<template>
  <div>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.name.$model"
          :class="$v.name.$error && 'is-invalid'"
          trim
        >
          <b-form-invalid-feedback v-if="!$v.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group label="Descrição">
        <b-form-textarea
          v-model="$v.description.$model"
          :class="$v.description.$error && 'is-invalid'"
          trim
        ></b-form-textarea>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button size="sm" variant="link" @click="$emit('submited')">
          Cancelar
        </b-button>
        <b-button
          size="sm"
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],

  data() {
    return {
      template_id: "",
      isLoading: false,
      name: null,
      description: null,
      admin: false,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    description: {
      required,
    },
  },
  methods: {
    ...mapActions([
      "add_template",
      "edit_template",
      "show_toast",
      "get_template",
    ]),

    afterSave(error) {
      this.isLoading = false;
      this.$emit("submited");
      if (error) {
        return this.show_toast({
          message: error,
          type: "error",
        });
      }
      this.show_toast({
        message: "Cadastro realizado com sucesso",
        type: "success",
      });
    },

    saveTemplate() {
      this.add_template({
        name: this.name,
        description: this.description,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.afterSave(null);
          }
        })
        .catch((err) => {
          this.afterSave(err);
        });
    },

    editTemplate() {
      this.edit_template({
        id: this.template_id,
        name: this.name,
        description: this.description,
      })
        .then(({ status, data }) => {
          if ([200, 201].includes(status)) {
            this.afterSave(null, data);
          }
        })
        .catch((err) => {
          this.afterSave(err, null);
        });
    },

    handleSubmitCredentials() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$anyError && this.$v.$anyError) {
        return;
      }

      if (this.template_id) {
        return this.editTemplate();
      }
      return this.saveTemplate();
    },

    getEdit() {
      this.name = "";
      this.description = "";

      if (this.template_id) {
        this.get_template({ id: this.template_id }).then(({ data }) => {
          this.name = data.name;
          this.description = data.description;
        });
      }
    },
  },

  mounted() {
    this.template_id = this.$route.params.id;
    if (this.template_id) {
      this.getEdit();
    }
  },
};
</script>

<style scoped lang="scss">
.form-data {
  max-width: 600px;
  margin: 0 auto;
}
</style>
